import { configureStore } from '@reduxjs/toolkit';
import notificationReducer from 'components/Notification/notificationSlice';

const store = configureStore({
    reducer: {
        notification: notificationReducer,
    },
});

export default store;
