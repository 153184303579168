import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: true,
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        close(state) {
            state.isOpen = false;
        },
    },
});

export const { close } = notificationSlice.actions;

export default notificationSlice.reducer;
